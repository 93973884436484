import type { Ref } from 'vue'
import { mdAndUp } from '~/composables/breakpoints'

/**
 * @description Prevent body overflow when dialog/panel/modal is open
 * @param preventOverflowScroll {Ref<boolean>} - Disable overflow scroll on body
 * @returns void
 */
export function usePreventBodyOverflow(preventOverflowScroll: Ref<boolean>): void {
  watchEffect(() => {
    // Required on client-side only
    if (process.client) {
      if (preventOverflowScroll.value) {
        document.body.style.overflowY = 'hidden'

        // Fix for iOS Safari
        // Force body to stay at the same position when the dialog is open
        if (!mdAndUp.value) {
          document.body.style.position = 'fixed'
          document.body.style.height = '100%'
        }
      } else {
        document.body.style.overflowY = 'auto'
        document.body.style.position = ''

        if (!mdAndUp.value) {
          document.body.style.height = 'auto'
        }
      }
    }
  })
}
